import React from 'react'
import './Nc.css';
import { Link } from 'react-router-dom';
function ACRYLICTHINNER() {
  return (
    <div className='container-fluid'>
      <div className="row ">
        <img src="./img/main02.jpg" className='h_01 p-0' alt="" />
        {/* <p className='b_text text-light'>Welcome To Our World</p> */}
      </div>

      <div className='row mt-5'>
       <div className="col-sm-5 m-auto p-sm-5 p-md-2" >
        <h1 style={{ color:'#e0be94', textShadow:'3px 0 #16365E' }}><em>
  <span style={{ fontFamily: 'Georgia' }}>Classic </span>
</em>
 Acrylic Thinner</h1>
        <p className='fs-5' style={{  color:'#16365E',textAlign: 'justify', textJustify: 'newspaper' }}>Introducing our "<em>
  <span style={{ fontFamily: 'Georgia', fontSize: '30px' }}>Classic </span>
</em>
 Acrylic Thinner," a versatile solvent engineered with precision to elevate the performance of acrylic-based coatings and paints. Meticulously formulated, this thinner optimizes viscosity and enhances paint flow, ensuring a seamless and even finish. Tailored for both professionals and DIY enthusiasts, it facilitates an ideal paint consistency, fostering superior adhesion and durability. With a commitment to meeting the highest standards of quality and performance, our acrylic thinner transforms your painting experience. Experience the perfect blend of versatility and excellence as you achieve remarkable results with this meticulously crafted solution, designed to elevate the outcome of your acrylic-based projects.</p>
        <Link to="/Contact" className='btn btn-primary mb-3'> Contact Us</Link>

       </div>
       <div className="col-sm-5 m-auto ">
       <img src="./img/Pro01.jpg" className='h_7 w-100 rounded rounded-5' alt="" />
       </div>
      </div>
      
      
    </div>
  )
}

export default ACRYLICTHINNER
