import React from 'react'
import './Nc.css';
import { Link } from 'react-router-dom';

function AutoGold() {
  return (
    <div className='container-fluid'>
      <div className="row ">
        <img src="./img/main08.jpg" className='h_01 p-0 ' alt="" />
        {/* <p className='b_text text-light'>Welcome To Our World</p> */}
      </div>

      <div className='row mt-5'>
       <div className="col-sm-5 m-auto p-sm-5 p-md-2" >
        <h1 style={{ color:'#e0be94', textShadow:'3px 0 #16365E' }}><em>
  <span style={{ fontFamily: 'Georgia'}}>Classic </span>
</em>
 Thinner Auto Gold</h1>
        <p className='fs-5' style={{  color:'#16365E',textAlign: 'justify', textJustify: 'newspaper' }}>“<em>
  <span style={{ fontFamily: 'Georgia', fontSize: '30px' }}>Classic </span>
</em>
 Thinner Auto Gold” is an upgraded version of our N.C. Thinner. It is high quality solvent based Nitro cellulose thinner, formulated by mixing of chemicals of best possible quality. It reduces the viscosity of lacquer paints and provides it the perfect viscosity to give desired results. It offers great help for spray painting technique and is used for solvent based nitro cellulose paints and lacquers to give a smooth finish and high gloss to both interior and exterior surfaces. It boasts of a quality which is of no match to the existing products in the market and is used in many industries and offers desired results. We proudly say that, our Gold series Thinner’s are the first choice of acclaimed applicators for good quality work.</p>
 <Link to="/Contact" className='btn btn-primary mb-3'> Contact Us</Link>


       </div>
       <div className="col-sm-5 m-auto ">
       <img src="./img/Pro07.jpg" className='h_7 w-100 rounded rounded-5' alt="" />
       </div>
      </div>
      
      
    </div>
  )
}

export default AutoGold
