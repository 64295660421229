import React , {useEffect, useRef} from 'react';
import './Contact.css';
import emailjs from '@emailjs/browser';
import './Contact01.css';
import Aos from 'aos';
import 'boxicons';


function Contact() {
  useEffect(()=> {
    Aos.init();     
  }, [])
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_spznf0h', 'template_jcb335l', form.current, {
        publicKey: 'W9u7ps52CZCMA5Uak',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          console.log("msg sent");
          form.current.reset();
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };
 
  return (
    <div className='container-fluid '>
      <div className="row mt-5">
        <img src="./img/AboutUs.jpg" className='h_01 p-0' alt="" />
        <div className='b_text t_03 col-md-4 col-xm-6 col-sm-6' data-aos ="fade-right" data-aos-delay="600">
            {/* <div className=' text-center  rounded  p-2 bg_top' >
            <p className='fs-1  fw-bolder'>Contact Us  </p>
            <h4>For all types of queries</h4>
            </div> */}
            </div>
        {/* <p className='b_text text-light' data-aos ="fade-right" data-aos-delay="600">Welcome To Our World</p> */}
      </div>
      {/* <---------------------------------------------------------------------------------->\ */}
    

<div className="row my-sm-4" data-aos ="fade-up" data-aos-delay="600">
            <div className="col-sm-6 col-lg-4">
            <div className='d-flex k card-body my-4 py-5  rounded-3'>
           <div className='p-2  pt-3 m-3 bg-light px-3  rounded-circle'>
           <box-icon name='phone' type='solid' color='rgba(13, 110, 253,9)' ></box-icon>
           </div>
            <div className='mx-2'>
            <p class="card-text "><small class="text-body-secondary">Call Us Now</small></p> 
              <h5>+91-9899694370</h5>
            </div>
          </div>
            </div>
            <div className="col-sm-6 col-lg-4">
            <div className='d-flex k card-body my-4 py-5  rounded-3'>
           <div className='p-2  pt-3 m-3 bg-light px-3  rounded-circle'>
           <box-icon name='phone' type='solid' color='rgba(13, 110, 253,9)' ></box-icon>
           </div>
            <div className='mx-2'>
            <p class="card-text "><small class="text-body-secondary">Address</small></p> 
              <h5>A-82, SECTOR-5 NOIDA-201301 (U.P.)</h5>
            </div>
          </div>
            </div>
            <div className="col-sm-6 col-lg-4">
            <div className='d-flex k card-body my-4 py-5  rounded-3'>
           <div className='p-2  pt-3 m-3 bg-light px-3  rounded-circle'>
           <box-icon name='phone' type='solid' color='rgba(13, 110, 253,9)' ></box-icon>
           </div>
            <div className='mx-2'>
            <p class="card-text"><small class="text-body-secondary">Mail Us Now</small></p>
              <h5>info@krishnapaints andchemicals.com</h5>
            </div>
          </div>
            </div>
           
            
            
        </div>
      
      {/* <--------------------------------------------------------------------------------------> */}
      <div className="row">
        <div className="col-lg-6 pb-5" data-aos ="fade-right" data-aos-delay="600">
        <iframe className='w-100 h-100' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14013.101934412032!2d77.3229203!3d28.5915113!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce59205e65359%3A0xc89ccd7f9343753!2sKrishna%20Paints%20and%20Chemicals!5e0!3m2!1sen!2sin!4v1710499869275!5m2!1sen!2sin" style={{  border:0, allowfullscreen:"", loading:"lazy", referrerpolicy:"no-referrer-when-downgrade",}}></iframe>
        
        </div>
        <div className="col-lg-6 p-5 mb-5 k " data-aos ="fade-up" data-aos-delay="600">
            <h1>
                Contact Us
            </h1>
            <form  ref={form} onSubmit={sendEmail}>
        {/* <label>Name</label>
      <input type="text" name="from_name" />
      <label>Email</label>
      <input type="email" name="user_email" />
      <label>Phone</label>
      <input type="text" name="user_Phone" />
      <label>Message</label>
      <textarea name="message" />
      <input type="submit" value="Send" />
           */}
          <div className="row">
            <div className="col-sm-6 mt-3  ">
            <input type="text" name="from_name" className='text-center w-100 mx-auto p-3 border-0 rounded-3 '  required placeholder='First Name'  />
            </div>
            <div className="col-sm-6 mt-3 ">
            <input type="text" className='text-center w-100 mx-auto p-3 border-0 rounded-3' required placeholder='Last Name' />
            </div>
            <div className="col-sm-6 mt-3 ">
            <input type="text" name="user_email" className='text-center w-100 mx-auto p-3 border-0 rounded-3' required  pattern="[^@\s]+@[^@\s]+\.[^@\s]+" placeholder='Your Email' title='use @ and .' />
            </div>
            <div className="col-sm-6 mt-3">
            <input type="text" name="user_Phone" className='text-center w-100 mx-auto p-3 border-0 rounded-3' required placeholder='Phone no'/>
            </div>
            <div className=" mt-3">
            <input type="text" name="message" className='text-center w-100 mx-auto p-3 border-0 rounded-3' required placeholder='Leave your message here....'/>
            </div>
           
            {/* <div className="col-12  mt-3">
              <textarea name="message"  className="form-control border-0 w-100" rows="5" placeholder='Describe your Problem'></textarea>
            </div> */}
            <div className="col-12 my-3">
            <input type={"submit"}
          className='bg-primary w-100 rounded border-0 text-light fs-5 p-3' placeholder='Book Appointment' /> 
            </div>
          </div>
         </form>
        </div>
      </div>



    </div>
  )
}

export default Contact
