import React from 'react'
import './Nc.css';
import { Link } from 'react-router-dom';

function SYNTHETICTHINNER() {
  return (
    <div className='container-fluid'>
      <div className="row ">
        <img src="./img/main13.jpg" className='h_01 p-0' alt="" />
        {/* <p className='b_text text-light'>Welcome To Our World</p> */}
      </div>

      <div className='row mt-5'>
       <div className="col-sm-5 m-auto p-sm-5 p-md-2" >
        <h1 style={{ color:'#e0be94', textShadow:'3px 0 #16365E' }}><em>
  <span style={{ fontFamily: 'Georgia'}}>Classic </span>
</em> Synthetic Thinner</h1>
        <p className='fs-5' style={{  color:'#16365E',textAlign: 'justify', textJustify: 'newspaper' }}>“<em>
  <span style={{ fontFamily: 'Georgia', fontSize: '30px' }}>Classic </span>
</em> Synthetic Thinner” is a blend of solvents which is used to reduce the viscosity of synthetic enamel paints. It has properties of quick drying. Our product is a fine blend which gives a smooth texture to enamel paints which helps in smoothening and conditioning of the surface after application. It is so formulated that, it does not have a pungent smell which makes it perfect for indoor application.</p>
<Link to="/Contact" className='btn btn-primary mb-3'> Contact Us</Link>

       </div>
       <div className="col-sm-5 m-auto ">
       <img src="./img/Pro02.jpg" className='h_7 w-100 rounded rounded-5' alt="" />
       </div>
      </div>
      
      
    </div>
  )
}

export default SYNTHETICTHINNER
