import 'bootstrap/dist/js/bootstrap.bundle';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'aos/dist/aos.css';
import Products from './Components/Product';
import Contact from './Components/Contact';
import About from './Components/About';
import Home from './Components/home';
// import Navbar from './Components/Navbar'
import Footer from './Components/Footer'
import Nc from './Components/Nc';
import FRENCH from './Components/FRENCH';
import AutoGold from './Components/AutoGold';
import MELAMINETHINNER from './Components/MELAMINETHINNER';
import PUTHINNER from './Components/PUTHINNER';
import SYNTHETICTHINNER from './Components/SYNTHETICTHINNER';
import RETARDERTHINNER from './Components/RETARDERTHINNER';
import ACRYLICTHINNER from './Components/ACRYLICTHINNER';
import DATTHINNER from './Components/DATTHINNER';
import BUTYLTHINNER from './Components/BUTYLTHINNER';
import FRENCHGOLD from './Components/FRENCHGOLD';
import Eposy01 from './Components/Eposy01';
import Trading from './Components/Trading';
import Navbar01 from './Components/Navbar01';
import FnQ from './Components/FnQ';
import Croff from './Components/Croff';

function App() {
  return (
    <BrowserRouter>
    <Navbar01/>
    <Routes>
      <Route path='' element={<Home/>} />
      <Route path='/About' element={<About/>}/>
      <Route path='/Product' element={<Products/>}/>
      <Route path='/Contact' element={<Contact/>}/>
      <Route path='/NC' element={ <Nc/> }/>
      <Route path='/FRENCH' element={ <FRENCH/> }/>
      <Route path='/AutoGold' element={ <AutoGold/> }/>
      <Route path='/MELAMINETHINNER' element={ <MELAMINETHINNER/> }/>
      <Route path='/PUTHINNER' element={ <PUTHINNER/> }/>
      <Route path='/SYNTHETICTHINNER' element={ <SYNTHETICTHINNER/> }/>
      <Route path='/RETARDERTHINNER' element={ <RETARDERTHINNER/> }/>
      <Route path='/ACRYLICTHINNER' element={ <ACRYLICTHINNER/> }/>
      <Route path='/DATTHINNER' element={ <DATTHINNER/> }/>
      <Route path='/Epoxy01' element={ <Eposy01/> }/>
      <Route path='/BUTYLTHINNER' element={ <BUTYLTHINNER/> }/>
      <Route path='/FRENCHGOLD' element={ <FRENCHGOLD/> }/>
      <Route path='/BUTYLTHINNER' element={ <BUTYLTHINNER/> }/>
      <Route path='/Trading' element={ <Trading/> }/>
      <Route path='/FnQ' element={ <FnQ/> }/>
      <Route path='/athinner' element={ <Croff/> }/>

      

    </Routes>
    <Footer/>
    </BrowserRouter>
  );
}

export default App;
