import React from 'react';
import "./Home.css";
import 'boxicons';
import { Link } from 'react-router-dom';

function home() {
  return (
    <div className='container-fluid '>
        <div className="row mt-5  ">
        <div id="carouselExampleIndicators"  className=" h_01  col-12 p-0 carousel slide"  data-bs-ride="carousel">
  <div className="carousel-indicators" id='b'>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div className="carousel-inner ">
    <div className="carousel-item active">
      <img src="./img/Crousal11.jpg" className="d-block position-relative w-100 h_01" alt="carousel-1"/>
      {/* <h1 className='A_top_02 text-center text-light'>Lorem, ipsum dolor sit amet consectetur adipisicing elit.  </h1> */}
      
    </div>
    <div className="carousel-item ">
      <img src="./img/Crousal22.jpg" className="d-block position-relative w-100 h_01" alt="carousel-2"/>
      {/* <h1 className='A_top_02 text-center text-light'>Lorem, ipsum dolor sit amet consectetur adipisicing elit.  </h1> */}
    
    </div>
    <div className="carousel-item">
      <img src="./img/Crousal03.jpg" className="d-block position-relative w-100 h_01" alt="carousel-3"/>
      {/* <h1 className='A_top_02 text-center text-light'>Lorem, ipsum dolor sit amet consectetur adipisicing elit.  </h1> */}
    
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
                  </div>
        </div>
        {/* <-------------------------------------------------------------------------> */}
       
{/* <-----------------------------------Our-Products--------------------------------------> */}
        <div className="row mt-5">
        <h3 className='text-center' style={{ color: '#55361D' }}>OUR PRODUCTS</h3>
            <p className='text-center fs-5' style={{ color: '#16365E' }}>Get an Extra Coating of Satisfaction with every finish.</p>
        </div>
        <div className="row my-4 col-md-9 rounded  m-auto  mb-5">
         
            <div className="col-sm-4  ">
            <div className="card border-0 pt-0 p-3" >
            <Link to="/athinner"><img src="./img/1.jpg" className="card-img-top " alt="..."/>
              </Link>
            <div className="card-body mt-0 pt-0">
            <p  className='text-center fs-5 my-0' style={{ color: '#16365E' }}><em>
  <span style={{ fontFamily: 'Georgia'}}>Classic </span>
</em>Auto Thinner</p>
            {/* <p className="card-text">Our Thinner is appreciated for giving... </p> */}
  </div>
</div>
            </div>
            <div className="col-sm-4  ">
            <div className="card border-0 pt-0 p-3" >
            <Link to="/FRENCH"><img src="./img/2.jpg" className="card-img-top " alt="..."/>
              </Link>
            <div className="card-body mt-0 pt-0">
            <p  className='text-center fs-5 my-0' style={{ color: '#16365E' }}><em>
  <span style={{ fontFamily: 'Georgia' }}>Classic </span>
</em> French Polish Thinner</p>
            {/* <p className="card-text">Our Thinner is appreciated for giving... </p> */}
  </div>
</div>
            </div>
            <div className="col-sm-4  ">
            <div className="card border-0 pt-0 p-3" >
            
            <Link to="/NC"><img src="./img/6.jpg" className="card-img-top " alt="..."/>
              </Link>
            <div className="card-body mt-0 pt-0">
            <p  className='text-center fs-5 my-0' style={{ color: '#16365E' }}><em>
  <span style={{ fontFamily: 'Georgia'}}>Lustre </span>
</em> N.C Thinner</p>
            {/* <p className="card-text">Our Thinner is appreciated for giving... </p> */}
  </div>
</div>
            </div>
            <div className="col-sm-4  ">
            <div className="card border-0 pt-0 p-3" >
            <Link to="/FRENCHGOLD"><img src="./img/FranchGold.jpg" className="card-img-top " alt="..."/>
              </Link>
            <div className="card-body mt-0 pt-0">
            <p  className='text-center fs-5 my-0' style={{ color: '#16365E' }}><em>
  <span style={{ fontFamily: 'Georgia' }}>Classic </span>
</em>Gold French Polish</p>
            {/* <p className="card-text">Our Thinner is appreciated for giving... </p> */}
  </div>
</div>
            </div>
            <div className="col-sm-4  ">
            <div className="card border-0 pt-0 p-3" >
            
              <Link to="/AutoGold"><img src="./img/AUtoGold.jpg" className="card-img-top " alt="..."/>
              </Link>
            <div className="card-body mt-0 pt-0">
            <p  className='text-center fs-5 my-0' style={{ color: '#16365E' }}><em>
  <span style={{ fontFamily: 'Georgia'}}>Classic </span>
</em>Gold Auto Thinner</p>
            {/* <p className="card-text">Our Thinner is appreciated for giving... </p> */}
  </div>
</div>
            </div>
            <div className="col-sm-4  ">
            <div className="card border-0 pt-0 p-3" >
            <Link to="/PUTHINNER"><img src="./img/puthinner.jpg" className="card-img-top " alt="..."/>
              </Link>
            <div className="card-body mt-0 pt-0">
            <p  className='text-center fs-5 my-0' style={{ color: '#16365E' }}><em>
  <span style={{ fontFamily: 'Georgia'}}>Classic </span>
</em>P.U. Thinner</p>
            {/* <p className="card-text">Our Thinner is appreciated for giving... </p> */}
  </div>
</div>
            </div>


            
          
        </div>

    {/* <-----------------------------Creating-Real-Value-----------------------------------> */}
<div className="row  ">
  <div className="col-lg-6  p-sm-3 p-md-2" id='a' style={{ backgroundColor: '#16365E', color:'#e0be94' }}>
    <div className='m-sm-3 m-md-4 m-2 p-sm-2 p-md-3'>
    <h1>About Us</h1>
          <p style={{ textAlign: 'justify', textJustify: 'newspaper' }}>ABOUT US Established in 1986 by Sh. Ajay Sharma with a vision of offering a premier quality product, KRISHNA PAINTS AND CHEMICALS is among the leading manufacturers of various kinds of Thinners, Paints and Allied Products. The organisation has been rendering its services and quality product from its manufacturing facility situated at Noida in Uttar Pradesh. The ambition to be known as an apex quality manufacturer has lead the organisation to be the creator of a brand of thinner, popularly known as “<em><span style={{ fontFamily: 'Georgia' }}>Classic Thinner</span></em>”. “<em><span style={{ fontFamily: 'Georgia' }}>Classic Thinner </span></em>”, one of the most desired, famous and accepted brand of thinner in northern India with a vast and rapidly expanding dealer network, is a result of the organisation’s quest for quality over the years. </p>
    </div>
  </div>
  <div className="col-lg-6 p-0" id='b'>
    <img src="./img/K_paints0.jpg" className='h_04 w-100' alt="" />
  </div>
</div>

{/* <-------------------------------------Video----------------------------------------> */}
<div className="row ">
  <div className='video-container p-0 '>
      <video autoPlay loop controls className='w-100 '>
        <source src="./img/K_videos02.mp4" type="video/mp4" />
      </video>
<div className='text-overlay'>
<h1 className=' text-center text-light'>Trust | Quality | Commitment</h1>
<p className=' text-center text-light fs-5'>Come and Join our Ever-Expanding Family</p>
<button type="button" className="btn border border-1 rounded-pill  m-auto text-light px-4 d-flex">  Shop Now</button>
</div>


    </div>

    {/* <div className='video-container p-0'>
  <video autoPlay loop className='w-100'>
    <source src="./img/K_videos02.mp4" type="video/mp4" />
  </video>
  <div className='text-overlay'>
    <h1 className='text-center text-light'>Trust | Quality | Commitment</h1>
    <p className='text-center text-light fs-5'>Come and Join our Ever-Expanding Family</p>
    <button type="button" className="btn border border-1 rounded-pill m-auto text-light px-4 d-flex">Shop Now</button>
  </div>
</div> */}

      
</div>
        

        
      
    </div>
  )
}

export default home
