import React from 'react'
import './Nc.css';
import { Link } from 'react-router-dom';

function BUTYLTHINNER() {
  return (
    <div className='container-fluid'>
      <div className="row ">
        <img src="./img/main04.jpg" className='h_01 p-0' alt="" />
        {/* <p className='b_text text-light'>Welcome To Our World</p> */}
      </div>

      <div className='row mt-5'>
       <div className="col-sm-5 m-auto p-sm-5 p-md-2" >
        <h1 style={{ color:'#e0be94', textShadow:'3px 0 #16365E' }}><em>
  <span style={{ fontFamily: 'Georgia' }}>Classic </span>
</em>
 Butyl Thinner</h1>
        <p className='fs-5' style={{  color:'#16365E',textAlign: 'justify', textJustify: 'newspaper' }}>Explore the unparalleled performance of our <em>
  <span style={{ fontFamily: 'Georgia', fontSize: '30px' }}>Classic </span>
</em>
 Butyl Thinner, a cutting-edge solution designed to redefine coating excellence. Formulated for versatility, it effortlessly adjusts viscosity, ensuring optimal flow and adherence across various applications. With an unwavering focus on quality, our Butyl Thinner enhances the entire application process, consistently delivering professional-grade results. Elevate your projects with confidence, as this premium thinner exemplifies our commitment to excellence, offering unmatched precision and reliability for a flawless finish every time. Trust in the Butyl Thinner for a seamless and refined coating experience, embodying the epitome of innovation and quality in every application.</p>
 <Link to="/Contact" className='btn btn-primary mb-3'> Contact Us</Link>


       </div>
       <div className="col-sm-5 m-auto ">
       <img src="./img/Pro06.jpg" className='h_7 w-100 rounded rounded-5' alt="" />
       </div>
      </div>
      
      
    </div>
  )
}

export default BUTYLTHINNER
