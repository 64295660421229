import React from 'react'
import './Nc.css';

function Products() {
  return (
    <div className='container-fluid'>
      <div className="row">
        <img src="./img/main06.jpg" className='h_01 p-0'  alt="" />
        {/* <p className='text-overlay text-light'>Welcome To Our World</p> */}
      </div>

      <div className='row mt-5'>
       <div className="col-md-5 m-auto p-sm-5 p-md-2" >
        <h1 style={{ color:'#e0be94', textShadow:'3px 0 #16365E' }}>Lustre N.C. Thinner</h1>
        <p className='fs-5' style={{  color:'#16365E',textAlign: 'justify', textJustify: 'newspaper' }} >"Lustre N.C. Thinner" is a premium solvent-based Nitrocellulose thinner renowned for its superior quality. Specifically designed to lower the viscosity of lacquer paints, it combines esters, glycols, alcohol, aromatic solvents, and ketones. This potent mixture proves invaluable for spray painting techniques, enhancing the application of solvent-based nitrocellulose paints and lacquers. The result is a flawless finish, providing a high gloss on both interior and exterior surfaces. A versatile solution across various industries, "Lustre N.C. Thinner" consistently delivers exceptional results, making it a preferred choice for achieving desired outcomes in paint and finishing applications.

</p>
<a href="/Auto" className='btn btn-primary'> Contact Us</a>

       </div>
       <div className="col-md-5 m-auto ">
       <img src="./img/Pro011.jpg" className='h_7 w-100 rounded rounded-5' alt="" />
       </div>
      </div>
      
      
    </div>
  )
}

export default Products
