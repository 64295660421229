import React from 'react'
import './Nc.css';
import { Link } from 'react-router-dom';

function MELAMINETHINNER() {
  return (
    <div className='container-fluid '>
      <div className="row ">
        <img src="./img/main12.jpg" className='h_01 p-0' alt="" />
        {/* <p className='b_text text-light'>Welcome To Our World</p> */}
      </div>

      <div className='row mt-5'>
       <div className="col-sm-5 m-auto p-sm-5 p-md-2" >
        <h1 style={{ color:'#e0be94', textShadow:'3px 0 #16365E' }}><em>
  <span style={{ fontFamily: 'Georgia'}}>Classic </span>
</em> MELAMINE THINNER</h1>
        <p className='fs-5' style={{  color:'#16365E' }}>Introducing our "<em>
  <span style={{  color:'#16365E',textAlign: 'justify', textJustify: 'newspaper' }}>Classic </span>
</em> Melamine Thinner," an expertly crafted solvent designed for effortlessly thinning melamine-based coatings and finishes. Tailored to preserve the integrity and performance of melamine products, this specialized thinner ensures optimal viscosity, facilitating a smooth application process and delivering durable results. Streamlining the coating process, it enhances flow, leveling, and adhesion, adhering to industry standards. Trust in our Melamine Thinner for impeccable finishes, consistently maintaining superior quality in every project. Elevate your coating experience with this precision-formulated solution, assuring reliable performance and exceptional outcomes for all your melamine-based endeavors.</p>
<Link to="/Contact" className='btn btn-primary mb-3'> Contact Us</Link>

       </div>
       <div className="col-sm-5 m-auto ">
       <img src="./img/Pro01.jpg" className='h_7 w-100 rounded rounded-5' alt="" />
       </div>
      </div>
      
      
    </div>
  )
}

export default MELAMINETHINNER
