import React from 'react'
import './Nc.css';
import { Link } from 'react-router-dom';

function FRENCH() {
  return (
    <div className='container-fluid'>
      <div className="row ">
        <img src="./img/main07.jpg" className='h_01 p-0' alt="" />
        {/* <p className='b_text text-light'>Welcome To Our World</p> */}
      </div>

      <div className='row mt-5'>
       <div className="col-sm-5 m-auto p-sm-5 p-md-2" >
        <h1 style={{ color:'#e0be94', textShadow:'3px 0 #16365E' }}><em><span style={{ fontFamily: 'Georgia' }}>Classic </span></em> French Polish Thinner</h1>
        <p className='fs-5' style={{  color:'#16365E', textAlign: 'justify', textJustify: 'newspaper' }}>"
        <em>
  <span style={{ fontFamily: 'Georgia', fontSize: '30px',  }}>Classic </span>
</em>
 French Polish Thinner" is an impeccable blend of high-quality solvents, integral to the Furniture Industry for optimizing the viscosity of French polishes and dissolving materials like Lakh Dan Lac flakes. Renowned for its exceptional performance, our thinner imparts an ideal consistency to the polish, enhancing flow, smoothening the film, and safeguarding against decay. This formulation not only bestows a flawless appearance upon surfaces but also enriches the natural wood grains. With its protective attributes, it goes beyond aesthetics, fortifying against potential damage. Elevate your furniture finishes with our acclaimed French Polish Thinner, achieving a perfect blend of quality, aesthetics, and long-lasting protection.</p>
 <Link to="/Contact" className='btn btn-primary mb-3'> Contact Us</Link>

       </div>
       <div className="col-sm-5 m-auto ">
       <img src="./img/Pro010.jpg" className='h_7 w-100 rounded rounded-5' alt="" />
       </div>
      </div>
      
      
    </div>
  )
}

export default FRENCH
