import React from 'react';
import './Product.css';
import { Link } from 'react-router-dom';

function Product() {
  return (
    <div className='container-fluid'>
    <div className="row position-relative mt-5">
      <img src="./img/Crousal11.jpg" className='h_01  p-0' alt="" />
      {/* <p className='text-overlay text-light'>Welcome To Our World</p> */}
      {/* <p className=' text-overlay_01 F_01'>Welcome To Our World</p> */}
    </div>  
    {/* <----------------------------------------Products-------------------------------------> */}
    <div className="row ">
    <div className="col-md-6 p-sm-2" style={{ backgroundColor: '#16365E', color:'#e0be94' }}  id='a'>
    <div className='m-sm-5 m-lg-3 m-md-2 p-md-3 p-lg-5 m-2 p-sm-5'>
    <h1><em>
  <span style={{ fontFamily: 'Georgia'}}>Classic </span>
</em> Auto Thinner</h1>
    <p style={{ textAlign: 'justify', textJustify: 'newspaper' }}>“<em>
  <span style={{ fontFamily: 'Georgia', fontSize: '20px' }}>Classic </span>
</em>Auto Thinner” is a High Quality solvent based Nitro cellulose thinner, which is used to reduce the viscosity of lacquer paints, etc.. It is a mixture of esters, glycols, alcohol, aromatic solvents and ketones. Offers great help for spray painting technique and is used for solvent based nitro cellulose paints and lacquers to give a smooth finish and high gloss to both interior and exterior surfaces.  It boasts of a quality which can be used in many industries and offers desired results.</p>
<Link to="/athinner" className='btn btn-primary'> View Product</Link>
    </div>
  </div>
  <div className="col-md-6  p-0" id='b'>
    <img src="./img/pic06.jpg" className='h_07 w-100' alt="" />
  </div>
</div>
<div className="row ">
  
  <div className="col-md-6 p-0" id='b'>
    <img src="./img/pic010.jpg" className='h_07 w-100' alt="" />
  </div>
  <div className="col-md-6 p-sm-3" id='a' style={{ backgroundColor: '#16365E', color:'#e0be94' }}>
    <div className='m-sm-5 m-lg-3 m-md-2 p-md-3 p-lg-5 m-2 p-sm-3'>
    <h1><em>
  <span style={{ fontFamily: 'Georgia' }}>Classic </span>
</em> French Polish Thinner</h1>
    <p style={{ textAlign: 'justify', textJustify: 'newspaper' }}>“<em>
  <span style={{ fontFamily: 'Georgia', fontSize: '20px' }}>Classic </span>
</em> French Polish Thinner” is a blend of High Quality solvents. It is widely used in the Furniture Industry for reducing the viscosity of French polishes and dissolving Lakh Dan Lac flakes and alike. Our Thinner is appreciated for giving a perfect consistency to the Polish along with increasing the flow, smoothening of the film and protecting the polish from decaying. it gives a perfect look to  the surface, enhances the look of the wood grains along with protection from damage.</p>
<Link to="/FRENCH" className='btn btn-primary'> View Product</Link>
    </div>
  </div>
</div>
<div className="row ">
  <div className="col-md-6  p-sm-3" id='a' style={{ backgroundColor: '#16365E', color:'#e0be94' }}>
    <div className='m-sm-5 m-lg-3 m-md-2 p-md-3 p-lg-5 m-2 p-sm-3'>
    <h1 className='uppercase'><em>
  <span style={{ fontFamily: 'Georgia'}}>Classic </span>
</em> Auto Gold Thinner</h1>
    <p style={{ textAlign: 'justify', textJustify: 'newspaper' }}>“<em>
  <span style={{ fontFamily: 'Georgia', fontSize: '20px' }}>Classic </span>
</em> Thinner Auto Gold series” is an upgraded version of our N.C. Thinner. It is high quality solvent based Nitro cellulose thinner, formulated by mixing of chemicals of best possible quality. It reduces the viscosity of lacquer paints and provides it the perfect viscosity to give desired results. It offers great help for spray painting technique and is used for solvent based nitro cellulose paints and lacquers to give a smooth finish and high gloss to both interior and exterior surfaces. </p>
<Link to="/AutoGold" className='btn btn-primary'> View Product</Link>
    </div >
  </div>
  <div className="col-md-6  p-0" id='b'>
    <img src="./img/pic07.jpg" className='h_07 w-100' alt="" />
  </div>
</div>
<div className="row ">
  
  <div className="col-md-6  p-0">
    <img src="./img/pic05.jpg" className='h_07 w-100' alt="" />
  </div>
  <div className="col-md-6  p-sm-3" id='a' style={{ backgroundColor: '#16365E', color:'#e0be94' }}>
    <div className='m-sm-5 m-lg-3 m-md-2 p-md-3 p-lg-5 m-2 p-sm-4'>
    <h1><em>
  <span style={{ fontFamily: 'Georgia'}}>Classic </span>
</em> Gold French Polish Thinner </h1>
    <p style={{ textAlign: 'justify', textJustify: 'newspaper' }}>“<em>
  <span style={{ fontFamily: 'Georgia', fontSize: '20px' }}>Classic </span>
</em> Thinner F.P.R. Gold series” is an upgraded version of our French Polish Thinner of finest quality. It is a blend of Bestpossible Quality solvents. It is so formulated that it gives the surface of application the perfect look and strength. It's widely used in the Furniture Industry for reducing the viscosity of French polishes to perfection and dissolving Lakh Dana and Lac flakes.</p>
<Link to="/FRENCHGOLD" className='btn btn-primary'> View Product</Link>
    </div>
  </div>
</div>
<div className="row ">
<div className="col-md-6 p-sm-3 " id='a' style={{ backgroundColor: '#16365E', color:'#e0be94' }}>
    <div className='m-sm-5 m-lg-3 m-md-2 p-md-3 p-lg-5 m-2 p-sm-5'>
    <h1 className='uppercase'>Lustre N.C. Thinner</h1>
    <p style={{ textAlign: 'justify', textJustify: 'newspaper' }}>“Lustre N.C. Thinner” is a High Quality solvent based Nitro cellulose thinner, which is used to reduce the viscosity of lacquer paints, etc.. It is a mixture of esters, glycols, alcohol, aromatic solvents and ketones. Offers great help for spray painting technique and is used for solvent based nitro cellulose paints and lacquers to give a smooth finish and high gloss to both interior an exterior surfaces. It boasts o quality which can be used in many industries and offers desired results.</p>
<Link to="/NC" className='btn btn-primary'>View Product</Link>
    </div >
  </div>
  <div className="col-md-6 p-0" id='b'>
    <img src="./img/pic011.jpg"  className='h_07 w-100' alt="" />
  </div>
</div>
<div className="row ">
  
  <div className="col-md-6 p-0">
    <img src="./img/pic04.jpg" className='h_07 w-100' alt="" />
  </div>
  <div className="col-md-6 p-sm-2" style={{ backgroundColor: '#16365E', color:'#e0be94' }}>
    <div className='m-sm-5 m-lg-3 m-md-2 p-md-3 p-lg-5 m-2 p-sm-5 '>
    <h1><em>
  <span style={{ fontFamily: 'Georgia' }}>Classic </span>
</em> SYNTHETIC THINNER</h1>
    <p style={{ textAlign: 'justify', textJustify: 'newspaper' }}>“<em>
  <span style={{ fontFamily: 'Georgia', fontSize: '20px' }}>Classic </span>
</em>Synthetic Thinner” is a blend of solvents which is used to reduce the viscosity of synthetic enamel paints. It has properties of quick drying. Our product is a fine blend which gives a smooth texture to enamel paints which helps in smoothening and conditioning of the surface after application. It is so formulated that, it does not have a pungent smell which makes it perfect for indoor application.</p>
<Link to="/SYNTHETICTHINNER" className='btn btn-primary'> View Product</Link>
    </div>
  </div>
</div>
<div className="row ">
<div className="col-md-6 p-sm-2" id='a' style={{ backgroundColor: '#16365E', color:'#e0be94' }}>
    <div className='m-sm-5 m-lg-3 m-md-2 p-md-3 p-lg-5 m-2 p-sm-5'>
    <h1><em>
  <span style={{ fontFamily: 'Georgia' }}>Classic </span>
</em> Melamine Thinner</h1>
    <p style={{ textAlign: 'justify', textJustify: 'newspaper' }}>Our “<em>
  <span style={{ fontFamily: 'Georgia', fontSize: '20px' }}>Classic </span>
</em> Melamine Thinner ” is a specialized solvent crafted to effortlessly thin melamine-based coatings and finishes. Specially formulated to maintain the integrity and performance of melamine products, it ensures optimal viscosity for smooth application and durable results. Our thinner streamlines the coating process, enhancing flow, leveling, and adhesion while adhering to industry standards. Trust our melamine thinner for impeccable finishes and superior quality in every project.</p>
<Link to="/MELAMINETHINNER" className='btn btn-primary'> View Product</Link>
    </div >
  </div>
  <div className="col-md-6 p-0" id='b'>
    <img src="./img/pic03.jpg" className='h_07 w-100' alt="" />
  </div>
</div>
<div className="row ">
  
  <div className="col-md-6 p-0">
    <img src="./img/pic09.jpg" className='h_07 w-100' alt="" />
  </div>
  <div className="col-md-6 p-sm-2" style={{ backgroundColor: '#16365E', color:'#e0be94' }}>
  <div className='m-sm-5 m-lg-3 m-md-2 p-md-3 p-lg-5 m-2 p-sm-5'>
    <h1><em>
  <span style={{ fontFamily: 'Georgia'}}>Classic </span>
</em> Epoxy Thinner</h1>
    <p style={{ textAlign: 'justify', textJustify: 'newspaper' }}>“<em>
  <span style={{ fontFamily: 'Georgia', fontSize: '20px' }}>Classic </span>
</em> Epoxy Thinner” is a blend of several hydrocarbons and organic solvents. It is widely used to reduce the viscosity and give the desired consistency to all sorts of Epoxy Paint and primers. It carries an extra ordinary property of drying a surface quickly and helps attaining bubble free and  sagging finish. It carries a high demand in industries related to floor coatings and swimming pools to give resistance against any damage to the surface.</p>
<Link to="/Epoxy01" className='btn btn-primary'> View Product</Link>
    </div>
  </div>
</div>
<div className="row ">
<div className="col-md-6 p-sm-2" style={{ backgroundColor: '#16365E', color:'#e0be94' }}  id='a'>
    <div className='m-sm-5 m-lg-3 m-md-2 p-md-3 p-lg-5 m-2 p-sm-5 '>
    <h1><em>
  <span style={{ fontFamily: 'Georgia' }}>Classic </span>
</em> P.U. Thinner</h1>
    <p style={{ textAlign: 'justify', textJustify: 'newspaper' }}>“<em>
  <span style={{ fontFamily: 'Georgia', fontSize: '20px' }}>Classic </span>
</em>P.U. Thinner” is a blend of aromatic hydrocarbon. ester and ketones. Our P.U. Thinner is widely used for dilu- tion of all type of Polyurethane paints, sealer, primer, clear and pigmented top coats to reduce their viscosity to desire. it protects the final finish from withering, gives a high gloss fini sh and makes it usable for spray painting. It is formulated according to international standards and is widely used in automotive and furniture industry.</p>
<Link to="/PUTHINNER" className='btn btn-primary'> View Product</Link>
    </div>
  </div>
  <div className="col-md-6 p-0 " id='b'>
    <img src="./img/pic012.jpg" className='h_07 w-100' alt="" />
  </div>
</div>
<div className="row ">
  
  <div className="col-md-6 p-0">
    <img src="./img/pic08.jpg" className='h_07 w-100' alt="" />
  </div>
  <div className="col-md-6 p-sm-2" style={{ backgroundColor: '#16365E', color:'#e0be94' }}>
    <div className='m-sm-5 m-lg-3 m-md-2 p-md-3 p-lg-5 p-sm-5'>
    <h1><em>
  <span style={{ fontFamily: 'Georgia' }}>Classic </span>
</em> D.A.T Thinner</h1>
    <p style={{ textAlign: 'justify', textJustify: 'newspaper' }}>“Our “<em>
  <span style={{ fontFamily: 'Georgia', fontSize: '20px' }}>Classic </span>
</em> D.A.T Thinner” product, is a specialized solvent designed to enhance the performance of coatings. Crafted to perfection, it efficiently thins and optimizes the viscosity of various finishes, ensuring a flawless application. With a commitment to quality, D.A.T Thinner guarantees superior flow, leveling, and adhesion for an impeccable, professional finish. Trust our thinner to elev ate your coatings to new heights, embodying precision and excellence in every application.</p>
<Link to="/DATTHINNER" className='btn btn-primary'> View Product</Link>
    </div>
  </div>
</div>
<div className="row">
  <div className="col-md-6 p-sm-2" id='a' style={{ backgroundColor: '#16365E', color:'#e0be94' }}>
    <div className='m-sm-5 m-lg-3 m-md-2 p-md-3 p-lg-5 m-2 p-sm-5'>
    <h1><em>
  <span style={{ fontFamily: 'Georgia'}}>Classic </span>
</em>Butyl Thinner</h1>
    <p style={{ textAlign: 'justify', textJustify: 'newspaper' }}>Introducing our <em>
  <span style={{ fontFamily: 'Georgia', fontSize: '20px' }}>Classic </span>
</em> Butyl Thinner, a cutting-edge solution for superior coating performance. Specially formulated for versatility, it effortlessly adjusts viscosity, ensuring optimal flow and adherence. With a focus on quality, our Butyl Thinner enhances the application process, delivering professional-grade results. Elevate your projects with confidence, as this premium thinner exemplifies our commitment to excellence, offering unmatched precision and reliability for a flawless finish every time.</p>
<Link to="/BUTYLTHINNER" className='btn btn-primary'> View Product</Link>
    </div >
  </div>
  <div className="col-md-6 p-0">
    <img src="./img/pic02.jpg" className='h_07 w-100' alt="" />
  </div>
</div>
<div className="row ">
  
  <div className="col-md-6 p-0">
    <img src="./img/pic01.jpg" className='h_07 w-100' alt="" />
  </div>
  <div className="col-md-6 p-sm-2" style={{ backgroundColor: '#16365E', color:'#e0be94' }}>
    <div className='m-sm-5 m-lg-3 m-md-2 p-md-3 p-lg-5 m-2 p-sm-5'>
    <h1><em>
  <span style={{ fontFamily: 'Georgia'}}>Classic </span>
</em> Acrylic Thinner</h1>
    <p style={{ textAlign: 'justify', textJustify: 'newspaper' }}>“<em>
  <span style={{ fontFamily: 'Georgia', fontSize: '20px' }}>Classic </span>
</em> Acrylic Thinner” is our versatile solution designed to dilute and improve the flow of acrylic paints, ensuring smooth application and vibrant results. Formulated for artists, this essential medium enhances paint consistency and facilitates effortless blending, contributing to the creation of captivating and professional artworks.</p>
<Link to="/ACRYLICTHINNER" className='btn btn-primary'> View Product</Link>
    </div>
  </div>
</div>

<div className="row">
<div className="col-md-6 p-sm-3 " id='a' style={{ backgroundColor: '#16365E', color:'#e0be94' }}>
<div className='m-sm-5 m-lg-3 m-md-2 p-md-3 p-lg-5   m-2 p-sm-5'>
    <h1><em>
  <span style={{ fontFamily: 'Georgia'}}>Classic </span>
</em> Retarder Thinner</h1>
    <p style={{ textAlign: 'justify', textJustify: 'newspaper' }}>Our"<em>
  <span style={{ fontFamily: 'Georgia', fontSize: '20px' }}>Classic </span>
</em> Retarder Thinner" is a specially formulated solvent designed to extend drying times and enhance the application of coatings and paints. This high-quality thinner is ideal for achieving optimal viscosity, allowing for improved leveling and a smoother finish. It is meticulously crafted to meet industry standards, ensuring compatibility with various coatings. Our Retarder Thinner provides the perfect solution for professionals seeking precise control over drying times and an impeccable fini sh in their projects.</p>
<Link to="/RETARDERTHINNER" className='btn btn-primary'> View Product</Link>
    </div>
  </div>
  <div className="col-md-6 p-0">
    <img src="./img/pic013.jpg" className='h_07 w-100' alt="" />
  </div>
</div>
{/* <div className="row ">
  <div className="col-md-6 h_04 p-5" style={{ backgroundColor: '#16365E', color:'#e0be94' }}>
    <div className='m-5 p-5'>
    <h1>Classic Auto Thinner</h1>
    <p>“Classic Auto Thinner” is a High Quality solvent based Nitro cellulose thinner, which is used to reduce the viscosity of lacquer paints, etc.. It is a mixture of esters, glycols, alcohol, aromatic solvents and ketones. Offers great help for spray painting technique and is used for solvent based nitro cellulose paints and lacquers to give a smooth finish and high gloss to both interior and exterior surfaces. It boasts of a quality which can be used in many industries and offers desired results.</p>
<Link to="/Auto" className='btn btn-primary'> View Product</Link>
    </div>
  </div>
  <div className="col-md-6 h_04 p-0">
    <img src="./img/K_paints_09.jpg" className='h_04 w-100' alt="" />
  </div>
</div> */}











  

   

  </div>
  )
}

export default Product
