import React, { useEffect, useRef } from "react";
import { NavLink , Link } from 'react-router-dom';
import './Navbar.css';
function Navbar01() {
  return (
    <div className='container'>
        <div className="row ">
        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top p-0">
  <div className="container-fluid">
    <Link className="navbar-brand" href=''>  
    <img src="./img/logo_0.png" className="logo" alt=""/>
   </Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto ">
                  <li className="nav-item mx-2"> 
                      <NavLink className="nav-link   fs-5"  aria-current="page" to=''>Home</NavLink>
                    </li>
                    <li className="nav-item mx-2">
                      <NavLink className="nav-link  fs-5"  to="/Product">Product</NavLink>
                    </li>
                    <li className="nav-item mx-2">
                      <NavLink className="nav-link  fs-5"  to="/Trading">Trading</NavLink>
                    </li>
                    
                    <li className="nav-item mx-2">
                        <NavLink className="nav-link  fs-5"  to="/Contact">Contact Us</NavLink>
                      </li>
                      <li className="nav-item mx-2">
                          <NavLink className="nav-link  cat fs-5"  to="/About">About</NavLink> 
                        </li>
                        {/* <li className="nav-item mx-2">
                          <Link className="nav-link  cat fs-5"  to="https://blog.krishnapaintsandchemicals.com">Blog</Link> 
                        </li> */}
                      
                       
                </ul>
             
              </div>
  </div>
</nav>
    </div>
        </div>
  )
}

export default Navbar01
