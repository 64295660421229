import React from 'react'
import './Nc.css';
import { Link } from 'react-router-dom';

function PUTHINNER() {
  return (
    <div className='container-fluid'>
      <div className="row ">
        <img src="./img/main15.jpg" className='h_01 p-0' alt="" />
        {/* <p className='b_text text-light'>Welcome To Our World</p> */}
      </div>

      <div className='row mt-5'>
       <div className="col-sm-5 m-auto p-sm-5 p-md-2" >
        <h1 style={{ color:'#e0be94', textShadow:'3px 0 #16365E' }}><em>
  <span style={{ fontFamily: 'Georgia'}}>Classic </span>
</em> P.U. Thinner</h1>
        <p className='fs-5' style={{  color:'#16365E',textAlign: 'justify', textJustify: 'newspaper' }}>Our "<em>
  <span style={{ fontFamily: 'Georgia', fontSize: '30px' }}>Classic </span>
</em> P.U. Thinner" is a meticulously crafted blend of aromatic hydrocarbons, esters, and ketones. This versatile solution serves as a crucial diluent for various Polyurethane paints, including sealers, primers, and clear or pigmented topcoats, effectively reducing their viscosity to the desired level. This expert formulation safeguards the final finish from withering, imparting a high gloss finish suitable for spray painting applications. Conforming to international standards, our P.U. Thinner finds extensive use in the automotive and furniture industries. Elevate your finishing processes with this reliable solution, ensuring optimal viscosity, protection, and a superior gloss finish, all contributing to impeccable results.</p>
<Link to="/Contact" className='btn btn-primary mb-3'> Contact Us</Link>

       </div>
       <div className="col-sm-5 m-auto ">
       <img src="./img/Pro012.jpg" className='h_7 w-100 rounded rounded-5' alt="" />
       </div>
      </div>
      
      
    </div> 
  )
}

export default PUTHINNER
