import React from 'react'
import './About.css'

function About() {
  return (
    <div className='container-fluid'>
      <div className="row mt-5 ">
        <img src="./img/Crousal11.jpg " className='A_pic p-0' alt="" />
        {/* <p className='b_text text-light'>Welcome To Our World</p> */}
      </div>
      <div className="row my-5">
        <div className="col-md-5 m-auto">
          <h1>About Us</h1>
          <p style={{  color:'#16365E',textAlign: 'justify', textJustify: 'newspaper' }}>ABOUT US Established in 1986 by Sh. Ajay Sharma with a vision of offering a premier quality product, KRISHNA PAINTS AND CHEMICALS is among the leading manufacturers of various kinds of Thinners, Paints and Allied Products. The organisation has been rendering its services and quality product from its manufacturing facility situated at Noida in Uttar Pradesh. The ambition to be known as an apex quality manufacturer has lead the organisation to be the creator of a brand of thinner, popularly known as “<em><span style={{ fontFamily: 'Georgia' }}>Classic Thinner</span></em>”. “<em><span style={{ fontFamily: 'Georgia' }}>Classic Thinner </span></em>”, one of the most desired, famous and accepted brand of thinner in northern India with a vast and rapidly expanding dealer network, is a result of the organisation’s quest for quality over the years. This quest for quality is in turn due to the commitment of the promoters towards honest objectives and zeal to offer the best in class quality and services to all its stakeholders. The organisation firmly believes that quality is never achieved by accident and that it is the result of years of determined hard work, commitment, and zeal to excel. The organization is 150 9001-2015 certified, which again proves its ability to consistently provide products that meet applicable statutory and regulatory requirements and the highest quality norms.</p>
        </div>
        <div className="col-md-5 m-auto">
          <img src="./img/K_paints09.jpg" className='w-100 h_01 rounded rounded-5' alt="" />
        </div>
      </div>
      
    </div>
  )
}

export default About
