import React from 'react'
import { Link } from 'react-router-dom';
import './Nc.css';

function Croff() {
  return (
    <div className='container-fluid'>
      <div className="row ">
        <img src="./img/main05.jpg" className='h_01 p-0' alt="" />
        {/* <p className='b_text text-light'>Welcome To Our World</p> */}
      </div>

      <div className='row mt-5'>
       <div className="col-sm-5 m-auto p-sm-5 p-md-2" >
        <h1 style={{ color:'#e0be94', textShadow:'3px 0 #16365E' }}><em>
  <span style={{ fontFamily: 'Georgia' }}>Classic </span>
</em>
 Auto Thinner</h1>
        <p className='fs-5' style={{  color:'#16365E',textAlign: 'justify', textJustify: 'newspaper' }}>Introducing our "<em>
  <span style={{ fontFamily: 'Georgia', fontSize: '30px' }}>Classic </span>
</em>
 Auto Thinner," a top-tier solvent-based Nitrocellulose thinner that stands out for its high quality. Specifically crafted to reduce the viscosity of lacquer paints, this dynamic blend comprises esters, glycols, alcohol, aromatic solvents, and ketones. A crucial ally for spray painting techniques, it excels in enhancing solvent-based nitrocellulose paints and lacquers, imparting a smooth finish and high gloss to both interior and exterior surfaces. Its versatile quality finds application across various industries, ensuring desired results. The "<em>
  <span style={{ fontFamily: 'Georgia', fontSize: '30px' }}>Classic </span>
</em>
 Auto Thinner" is synonymous with excellence, offering a reliable solution to elevate the finishing process and achieve impeccable results in diverse applications.</p>
 <Link to="/Contact" className='btn btn-primary mb-3'> Contact Us</Link>

       </div>
       <div className="col-sm-5 m-auto ">
       <img src="./img/Pro05.jpg" className='h_7 w-100 rounded rounded-5' alt="" />
       </div>
      </div>
      
      
    </div>
  )
}

export default Croff
