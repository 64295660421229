import React from 'react'
import { Link } from 'react-router-dom';
import './Nc.css';
function FRENCHGOLD() {
  return (
    <div className='container-fluid'>
      <div className="row ">
        <img src="./img/main09.jpg" className='h_01 p-0 ' alt="" />
        {/* <p className='b_text text-light'>Welcome To Our World</p> */}
      </div>

      <div className='row mt-5'>
       <div className="col-sm-5 m-auto p-sm-5 p-md-2" >
        <h1 style={{ color:'#e0be94', textShadow:'3px 0 #16365E' }}><em>
  <span style={{ fontFamily: 'Georgia'}}>Classic </span>
</em> Gold French Polish Thinner</h1>
        <p className='fs-5' style={{  color:'#16365E',textAlign: 'justify', textJustify: 'newspaper' }}>"<em>
  <span style={{ fontFamily: 'Georgia', fontSize: '30px' }}>Classic </span>
</em> Thinner F.P.R. Gold series" is an upgraded version of our French Polish Thinner of finest quality. It is a blend of Bestpossible Quality solvents. It is so formulated that it gives the surface of application the perfect look and strength. It is widely used in the Furniture Industry for reducing the viscosity of French polishes to perfection and dissolving Lakh Dana and Lac flakes. Our Gold series thinner's are appreciated for giving a perfect consistency to the Polish along with increasing the flow, smoothening of the film and protecting the polish from decaying. It boasts of unmatched qualityand gives a perfect look to the surface, enhances the look of the wood grains along with protection from damage. We proudly say that, our Gold series Thinner's are the first choice of acclaimed applicators for good quality work.</p>
<Link to="/Contact" className='btn btn-primary mb-3'> Contact Us</Link>

       </div>
       <div className="col-sm-5 m-auto ">
       <img src="./img/Pro03.jpg" className='h_7 w-100 rounded rounded-5' alt="" />
       </div>
      </div>
      
      
    </div>
  )
}

export default FRENCHGOLD
