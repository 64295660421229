import React from 'react'
import './Nc.css';
import { Link } from 'react-router-dom';

function RETARDERTHINNER() {
  return (
    <div className='container-fluid'>
      <div className="row ">
        <img src="./img/main01.jpg" className='h_01 p-0' alt="" />
        {/* <p className='b_text text-light'>Welcome To Our World</p> */}
      </div>

      <div className='row mt-5'>
       <div className="col-sm-5 m-auto p-sm-5 p-md-2" >
        <h1 style={{ color:'#e0be94', textShadow:'3px 0 #16365E' }}><em>
  <span style={{ fontFamily: 'Georgia'}}>Classic </span>
</em> Retarder Thinner</h1>
        <p className='fs-5' style={{  color:'#16365E',textAlign: 'justify', textJustify: 'newspaper' }}>Introducing our "<em>
  <span style={{ fontFamily: 'Georgia', fontSize: '30px' }}>Classic </span>
</em> Retarder Thinner," a specially formulated solvent crafted to extend drying times and elevate the application of coatings and paints. This high-quality thinner is designed to achieve optimal viscosity, fostering improved leveling and a seamless finish. Meticulously crafted to meet industry standards, it ensures compatibility with various coatings, making it an ideal choice for professionals seeking precise control over drying times and impeccable finishes. Our Retarder Thinner provides a reliable and efficient solution, enhancing your coating experience. Elevate your projects with this versatile formulation, delivering precise control and exceptional results for those who demand excellence in every aspect of their work.</p>
<Link to="/Contact" className='btn btn-primary mb-3'> Contact Us</Link>

       </div>
       <div className="col-sm-5 m-auto ">
       <img src="./img/Pro013.jpg" className='h_7 w-100 rounded rounded-5' alt="" />
       </div>
      </div>
      
      
    </div>
  )
}

export default RETARDERTHINNER
