import React from 'react'
import './About.css'

function FnQ() {
  return (
    <div className='container-fluid'>
       <div className="row mt-5 ">
        <img src="./img/Crousal01.jpg " className='A_pic p-0' alt="" />
        {/* <p className='b_text text-light'>Welcome To Our World</p> */}
      </div>
      {/* <--------------------------------------------------------------------------------> */}
      <div className="row">
        <h1 className='text-center my-3'>FREQUENTLY ASKED QUESTIONS</h1>
        <div className="col-md-10 m-auto">
        <div class="accordion accordion-flush" id="accordionFlushExample">
  {/* <!-- Accordion Item #1 --> */}
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingOne">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
      WHERE IS KRISHNA PAINTS AND CHEMICALS LOCATED?
      </button>
    </h2>
    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Address: A-82, Sector 5, Noida, Uttar Pradesh 201301</div>
    </div>
  </div>

  {/* <!-- Repeat the structure for Accordion Items #2 to #8 --> */}
  {/* <!-- Accordion Item #2 --> */}
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
      WHAT ARE THE BUSINESS HOURS OF KRISHNA PAINTS AND CHEMICALS?
      </button>
    </h2>
    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Monday to Saturday: 10:00 AM - 6:00 PM</div>
    </div>
  </div>

  {/* <!-- Repeat the structure for Accordion Items #3 to #8 -->
  <!-- Accordion Item #3 --> */}
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
      WHAT PAYMENT METHODS ARE ACCEPTED BY KRISHNA PAINTS AND CHEMICALS?
      </button>
    </h2>
    <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Payments can be made via Cash and Cheques.
</div>
    </div>
  </div>

  {/* <!-- Repeat the structure for Accordion Items #4 to #8 -->
  <!-- Accordion Item #4 --> */}
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingFour">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
      WHAT PRODUCTS DOES KRISHNA PAINTS AND CHEMICALS MANUFACTURE?
      </button>
    </h2>
    <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Krishna Paints and Chemicals manufactures a wide range of thinners, including:
      <ul>
        <li>NC. Thinner</li>
        <li>French Polish Thinner</li>
        <li>Auto Thinner</li>
        <li>Epoxy Thinner</li>
        <li>Melamine Thinner</li>
        <li>PU. Thinner</li>
        <li>Synthetic Thinner</li>
        <li>Retarder Thinner</li>
        <li>Acrylic Thinner</li>
        <li>DAT Thinner</li>
        <li>Butyl Thinner</li>
        </ul>
        </div>
    </div>
  </div>

  {/* <!-- Accordion Item #5 --> */}
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingFive">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
      WHAT PRODUCTS DOES KRISHNA PAINTS AND CHEMICALS TRADE?
      </button>
    </h2>
    <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Krishna Paints and Chemicals trades various chemicals, including:
      <ul>
        <li>Ethyl Acetate</li>
        <li>Toluene</li>
        <li>Mix Xylene</li>
        <li>Diacetone Alcohol</li>
        <li>Hexane</li>
        <li>Butyl Acetate</li>
        <li>Butyl Cellosolve</li>
        <li>Acetone</li>
        <li>IPA (Isopropyl Alcohol)</li>
        <li>Normal Butyl Alcohol</li>
      </ul>
      </div>
    </div>
  </div>

  {/* <!-- Accordion Item #6 --> */}
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingSix">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
      CAN I VISIT KRISHNA PAINTS AND CHEMICALS DIRECTLY TO PURCHASE PRODUCTS?
      </button>
    </h2>
    <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Yes, you can visit the mentioned address during the operational hours to inquire about and purchase their products.</div>
    </div>
  </div>

  {/* <!-- Accordion Item #7 --> */}
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingSeven">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
      DO THEY OFFER A VARIETY OF THINNERS FOR DIFFERENT APPLICATIONS?
      </button>
    </h2>
    <div id="flush-collapseSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Yes, Krishna Paints and Chemicals provides a diverse range of thinners suitable for various purposes, including industrial coatings and finishes.</div>
    </div>
  </div>

  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingEight">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
      ARE THERE SPECIFIC THINNERS RECOMMENDED FOR AUTOMOTIVE APPLICATIONS?
      </button>
    </h2>
    <div id="flush-collapseEight" class="accordion-collapse collapse" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Yes, Krishna Paints and Chemicals manufactures Auto Thinner, which is designed for automotive paint applications.</div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingNine">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
      DO THEY OFFER DELIVERY SERVICES FOR THEIR PRODUCTS?
      </button>
    </h2>
    <div id="flush-collapseNine" class="accordion-collapse collapse" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Details about delivery services can be obtained by contacting Krishna Paints and Chemicals directly.</div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingTen">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
      IS THERE A SPECIFIC THINNER FOR EPOXY-BASED COATINGS?
      </button>
    </h2>
    <div id="flush-collapseTen" class="accordion-collapse collapse" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Yes, Krishna Paints and Chemicals produces Epoxy Thinner specifically formulated for epoxy coatings.</div>
    </div>
  </div>
</div>

        </div>
        
        
      </div>
      
    </div>
  )
}

export default FnQ
