import React from 'react'
import './Nc.css';
import { Link } from 'react-router-dom';

function DATTHINNER() {
  return (
    <div className='container-fluid '>
      <div className="row ">
        <img src="./img/main03.jpg" className='h_01 p-0' alt="" />
        {/* <p className='b_text text-light'>Welcome To Our World</p> */}
      </div>

      <div className='row mt-5'>
       <div className="col-sm-5 m-auto p-sm-5 p-md-2" >
        <h1 style={{ color:'#e0be94', textShadow:'3px 0 #16365E' }}><em>
  <span style={{ fontFamily: 'Georgia' }}>Classic </span>
</em> D.A.T Thinner</h1>
        <p className='fs-5' style={{  color:'#16365E',textAlign: 'justify', textJustify: 'newspaper' }}> Introducing our premier product, "<em>
  <span style={{ fontFamily: 'Georgia', fontSize: '30px' }}>Classic </span>
</em> D.A.T Thinner," a specialized solvent meticulously designed to elevate coating performance. This expertly crafted solution efficiently thins and optimizes the viscosity of diverse finishes, ensuring a flawless application process. Committed to unparalleled quality, D.A.T Thinner guarantees superior flow, leveling, and adhesion, resulting in an impeccable and professional finish. Trust in our thinner to elevate your coatings to new heights, embodying precision and excellence in every application. With a dedication to perfection, this specialized solvent becomes an indispensable tool for professionals seeking optimal results and superior performance in their coating endeavors. </p>
<Link to="/Contact" className='btn btn-primary mb-3'> Contact Us</Link>

       </div>
       <div className="col-sm-5 m-auto ">
       <img src="./img/Pro08.jpg" className='h_7 w-100 rounded rounded-5' alt="" />
       </div>
      </div>
      
      
    </div>
  )
}

export default DATTHINNER
