import React from 'react'
import { Link } from 'react-router-dom';
import './Footer.css'
import 'boxicons'


function Footer() {
  return (
    <div className='container-fluid '>
      <div className="row pt-4 mt-4 pb-4 border-end Foot_bg">
        <div className=" col-sm-3 ps-4">
            <h4 className="mb-4">Address</h4>
            <p><box-icon name='location-plus'  color='rgba(246,240,240,9)' ></box-icon>A-82, SEC-5 NOIDA-201301 (U.P.), India</p>
            <p><box-icon name='phone' type='solid' color='rgba(252,248,248,9)' ></box-icon>+91-9899694370</p>
            <p><box-icon name='envelope' color='rgba(246,240,240,9)' ></box-icon>info@krishnapaintsandchemicals.com</p>
            {/* <div className='d-flex'> 
                <div className='border border-1  border-light p-1 rounded-circle m-1'><box-icon name='twitter' type='logo' color='rgba(251,243,243,9)' ></box-icon></div>
                <div className='border border-1  border-light p-1 rounded-circle m-1'><box-icon name='facebook' type='logo' color='rgba(251,243,243,9)' ></box-icon></div>
                <div className='border border-1  border-light p-1 rounded-circle m-1'><box-icon name='youtube' type='logo' color='rgba(251,243,243,9)' ></box-icon></div>
                <div className='border border-1  border-light p-1 rounded-circle m-1'><box-icon name='linkedin' type='logo' color='rgba(244,237,237,9)' ></box-icon></div>
            </div> */}
        </div>

        <div className=" col-sm-3 ps-4">
            <h4 className="mb-4"><Link className="text-light text-decoration-none" style={{color:'#16365E' }} to="/Product">Our Products</Link></h4>
            <p><Link className="text-light text-decoration-none" style={{color:'#16365E' }} to="/NC">N.C Thinner</Link></p>
            {/* <p>French Polish Thinner</p> */}
            <p><Link className="text-light text-decoration-none" style={{color:'#16365E' }} to="/Epoxy01">Epoxy Thinner</Link></p>
            <p><Link className="text-light text-decoration-none" style={{color:'#16365E' }} to="/PUTHINNER">P.U. Thinner</Link></p>
            <p><Link className="text-light text-decoration-none" style={{color:'#16365E' }} to="/Product">Other Products</Link></p>
        </div>

        <div className=" col-sm-3 ps-4">
            <h4 className="mb-4">Quick Links</h4>
            <p><Link className="text-light text-decoration-none" style={{color:'#16365E' }} to="/About">About</Link></p>
            <p><Link className="text-decoration-none text-light" style={{color:'#16365E' }} to="/Contact">Contact Us</Link></p>
            <p><Link className="text-light text-decoration-none" style={{color:'#16365E' }} to="/Product">Our Products</Link></p>
            <p><Link className="text-light text-decoration-none" style={{color:'#16365E' }} to="/FnQ">FAQ</Link></p>
           
        </div>

        <div className=" col-sm-3 ps-4">
            <h4 className="mb-4"><Link className="text-light text-decoration-none" style={{color:'#16365E' }} to="/About">Trading Products</Link></h4>
            <p>Toluene</p>
            <p>Mix Xylene</p>
            <p>Ethyle Acetate</p>
            <p>Di-Acetone Alcohol</p>
           <div className="row d-flex">
                <div className="col-6"></div>
                <div className="col-6"></div>
            </div>
            
        </div> 
        <div className="row border-top mt-3">
        <div className=' mt-3 fs-6'> <span className='foot_s p-0'><box-icon name='copyright' color='#ffffff' ></box-icon></span> Copyrights 2024 | Krishna Paints & Chemicals | Designed by bizjunket.co.in  </div>
        <div></div>
    </div>
    </div>
   
    </div>
  )
}

export default Footer
