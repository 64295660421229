import React, { useRef } from 'react';
import 'boxicons'
import emailjs from '@emailjs/browser';
// import 'aos/dist/aos.css';

function Trading() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_spznf0h', 'template_jcb335l', form.current, {
        publicKey: 'W9u7ps52CZCMA5Uak',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          console.log("msg sent");
          form.current.reset();
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };
  
  return (
    <div className='container-fluid'>
       <div className="row mt-5">
        <img src="./img/Crousal11.jpg" className='h_01 p-0 ' alt="" />
        {/* <p className='b_text text-light'>Welcome To Our World</p> */}
      </div>
      {/* <div className="row my-5">
        <div className="col-sm-5 m-auto">
            <h1 className='fw-bold'>Our Trading Products</h1>
            <p>Our organisation also specializes in trading of various indegenious and imported industrial solvents and chemicals used in various industries such as paint, printing, rubber and printing ink industries:-</p>
            <ul>
                <li>Ethyle Acetate</li>
                <li>Toluene</li>
                <li>Mix Xylene</li>
                <li>Diacetone Alcohol</li>
                <li>Hexane</li>
                <li>Butyl Acetate</li>
                <li>Butyl Cellosolve</li>
                <li>AcetoneIPA</li>
                <li>IPA</li>
                <li>Normal Butyl Alcohol</li>
            </ul>
        </div>
        <div className="col-sm-5"></div>
      </div> */}
      <div className="row my-sm-5">
        <div className="col-lg-6 p-5 "  >
        <h1 className='fw-bold'>Our Trading Products</h1>
            <p>Our organisation also specializes in trading of various indegenious and imported industrial solvents and chemicals used in various industries such as paint, printing, rubber and printing ink industries. The products are also available in bulk and 200L Drums:-</p>
            <ul>
                <li>Ethyle Acetate</li>
                <li>Toluene</li>
                <li>Mix Xylene</li>
                <li>Di-Acetone Alcohol</li>
                <li>Hexane</li>
                <li>Butyl Acetate</li>
                <li>Butyl Cellosolve</li>
                <li>Acetone</li>
                <li>IPA</li>
                <li>Normal Butyl Alcohol</li>
            </ul>
        </div>
        <div className="col-lg-6 p-5  k ">
            <h1>
                Contact Us
            </h1>
        <form  ref={form} onSubmit={sendEmail}>
        {/* <label>Name</label>
      <input type="text" name="from_name" />
      <label>Email</label>
      <input type="email" name="user_email" />
      <label>Phone</label>
      <input type="text" name="user_Phone" />
      <label>Message</label>
      <textarea name="message" />
      <input type="submit" value="Send" />
           */}
          <div className="row">
            <div className="col-sm-6 mt-3  ">
            <input type="text" name="from_name" className='text-center w-100 mx-auto p-3 border-0 rounded-3 '  required placeholder='First Name'  />
            </div>
            <div className="col-sm-6 mt-3 ">
            <input type="text" className='text-center w-100 mx-auto p-3 border-0 rounded-3' required placeholder='Last Name' />
            </div>
            <div className="col-sm-6 mt-3 ">
            <input type="text" name="user_email" className='text-center w-100 mx-auto p-3 border-0 rounded-3' required  pattern="[^@\s]+@[^@\s]+\.[^@\s]+" placeholder='Your Email' title='use @ and .' />
            </div>
            <div className="col-sm-6 mt-3">
            <input type="text" name="user_Phone" className='text-center w-100 mx-auto p-3 border-0 rounded-3' required placeholder='Phone no'/>
            </div>
            <div className=" mt-3">
            <input type="text" name="message" className='text-center w-100 mx-auto p-3 border-0 rounded-3' required placeholder='Leave your message here....'/>
            </div>
           
            {/* <div className="col-12  mt-3">
            <textarea name="message" placeholder='Leave your message' className='w-100 border-0' />
            </div> */}
            <div className="col-12 my-3">
            <input type={"submit"}
          className='bg-primary w-100 rounded border-0 text-light fs-5 p-3' placeholder='Book Appointment' /> 
            </div>
          </div>
         </form>
        </div>
      </div>
<div className="row">
  
</div>


    </div>
  )
}

export default Trading
